<template>
  <div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        :class="ordersNo ? 'bottom-space' : ''"
        style="height: 100%"
        :finished-text="orders.length ? $t('没有更多了') : ''"
        @load="onLoad"
      >
        <div class="orders-content" v-if="orders && orders.length !== 0">
          <div
            class="orders-pay-all van-hairline--bottom"
            @click="checkAllOrders"
          >
            <Check :check="allChecked" style="display: inline-block" />
            <span style="display: inline-block; padding-left: 5px">{{
              $t("Select all amount cross-border orders", {
                amount: allAvailable,
              })
            }}</span>
          </div>
          <div style="height: 10px; background-color: #f2f2f2"></div>
          <div
            v-for="order in orders"
            :key="order.node.id"
            class="order-item"
            @click="toOrderDetail(order.node.id)"
          >
            <div class="order-item-title">
              <div style="flex: 1" @click.stop="checkOrder(order)">
                <div
                  v-if="isAli1688Vendor(order)"
                  style="display: inline-block"
                >
                  <Check
                    :check="order.node.checked"
                    :disable="!isReadyToTransit(order)"
                  />
                </div>
                <span style="padding-left: 5px">{{ getOrderNo(order) }}</span>
              </div>
              <span
                v-if="!isOrderPacked(order)"
                class="order-item-cancel"
                @click.stop="cancelOrder(order)"
                >{{ $t("Cancel Order") }}</span
              >
            </div>
            <div v-for="line in order.node.lineItems.edges" :key="line.node.id">
              <div
                class="cart-item van-hairline--top-bottom"
                v-if="line.node.product"
              >
                <van-image
                  width="80"
                  height="80"
                  @click.stop="toProduct(line.node.product.id)"
                  :src="displayImage(line.node)"
                />
                <div class="cart-item-content">
                  <div class="cart-item-bottom">
                    <div class="product-title">
                      {{
                        line.node.product.local_title
                          ? line.node.product.local_title.value
                          : line.node.product.title
                      }}
                    </div>
                    <span>฿{{ line.node.variant.price }}</span>
                  </div>
                  <div
                    class="cart-item-bottom"
                    style="color: #999; padding: 5px 0px"
                  >
                    <span style="flex: 1">
                      {{ getSelectOptions(line.node) }}
                    </span>
                    <span> x{{ line.node.quantity }} </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="freight-item">
              <span class="freight-item-title">{{ $t("Weight") }}</span>
              <span
                >{{
                  isReadyToTransit(order)
                    ? order.node.actual_weight &&
                      order.node.actual_weight.value + "KG"
                    : "--"
                }}
              </span>
            </div>
            <div class="freight-item">
              <span class="freight-item-title">{{ $t("Volume") }}</span>
              <span
                >{{
                  isReadyToTransit(order)
                    ? order.node.actual_volume &&
                      order.node.actual_volume.value + "CBM"
                    : "--"
                }}
              </span>
            </div>
            <div
              style="color: red"
              class="freight-item"
              v-if="!isReadyToTransit(order)"
            >
              {{ $t("Waiting for the goods to arrive at the warehouse") }}
            </div>
            <div style="height: 10px; background-color: #f2f2f2"></div>
          </div>
        </div>
      </van-list>

      <van-empty
        v-if="!orders.length"
        image="search"
        :style="{}"
        :description="$t('暂无数据')"
      />
    </van-pull-refresh>

    <div class="to-pay-bottom mobile-fixed-theme van-hairline--top-bottom" v-if="ordersNo">
      <div class="freight-item" style="margin-top: 10px">
        <span class="freight-item-title" style="font-weight: bold">{{
          $t("Additional Cross-border Freight")
        }}</span>
        <span class="copy" style="font-size: 0.8rem" @click="toShippingPolicy">
          {{ $t("See Rates >>") }}</span
        >
      </div>
      <div class="notice-to-ship" style="margin-bottom: 10px">
        {{
          $t(
            "amount cross-border orders will be shipped when the freight is paid",
            {
              amount: orders.length,
            }
          )
        }}
      </div>
      <div
        class="van-hairline--top-bottom"
        style="background-color: white; padding: 5px 0px"
        @click="showTransportationType"
      >
        <div class="freight-item">
          <span class="freight-item-title">{{
            $t("Transportation type")
          }}</span>
          <span>{{ expressWay ? expressWay.name : $t("Please Select") }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <div>
        <div class="freight-item">
          <span class="freight-item-title">{{ $t("Total Weight") }}</span>
          <span>{{ totalWeight ? totalWeight + "KG" : "--" }} </span>
        </div>
        <div class="freight-item">
          <span class="freight-item-title">{{ $t("Total Volume") }}</span>
          <span>{{ totalVolume ? totalVolume + "CBM" : "--" }}</span>
        </div>
        <!-- <div class="freight-item">
          <span class="freight-item-title">{{ $t("Rate") }}</span>
          <span>{{
            preorderFrieght && preorderFrieght.rate
              ? "฿" + preorderFrieght.rate
              : "--"
          }}</span>
        </div> -->
        <div class="freight-item">
          <span class="freight-item-title">{{ $t("Amount") }}</span>
          <span>{{
            preorderFrieght ? "฿" + preorderFrieght.totalFee : "--"
          }}</span>
        </div>
        <div class="freight-item-amount">
          <span class="freight-item-title"
            >{{ $t("Cross-border")
            }}{{
              preorderFrieght && preorderFrieght.rate
                ? "(" + preorderFrieght.rate + ")"
                : ""
            }}</span
          >
          <span>{{
            preorderFrieght ? "฿" + preorderFrieght.firstLegFreight : "--"
          }}</span>
        </div>
        <div class="freight-item-amount">
          <span class="freight-item-title">{{ $t("Deliver to door") }}</span>
          <span>{{
            preorderFrieght ? "฿" + preorderFrieght.secondLegFreight : "--"
          }}</span>
        </div>
      </div>

      <div class="ship-total van-hairline--top">
        <div class="to-pay-left">
          <span>{{ $t("orders amount", { amount: ordersNo }) }}</span>
          <br />
          <span
            >{{ $t("Total Freight") }}:
            {{ preorderFrieght ? "฿" + preorderFrieght.totalFee : "--" }}</span
          >
        </div>

        <van-button
          class="button-pay-now"
          @click="toPay"
          color="#EE502F"
          size="small"
          style="font-size: 0.9rem; width: 100px; border-radius: 5px"
        >
          {{ $t("Pay") }}
        </van-button>
      </div>
    </div>

    <van-dialog
      v-model="cancelShow"
      title=""
      show-cancel-button
      :cancelButtonText="$t('取消')"
      :confirmButtonText="$t('确定')"
      @confirm="onCancelConfirm"
    >
      <div style="padding: 20px 15px">
        <div style="text-align: center" v-if="!isOrderPacked(cOrder)">
          {{
            $t("Are you sure you want to cancel order", {
              orderName: cOrder && cOrder.node.name,
            })
          }}
        </div>
        <div v-if="isOrderPacked(cOrder)" style="text-align: center">
          {{
            $t(
              "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?"
            )
          }}
        </div>
      </div>
    </van-dialog>

    <van-action-sheet
      v-model="transportationShow"
      :actions="actions"
      :cancel-text="$t('取消')"
      @select="onSelect"
      :description="$t('Transportation type')"
      close-on-click-action
    />
  </div>
</template>

<script>
import {
  List,
  PullRefresh,
  Image as VanImage,
  Icon,
  Empty,
  NoticeBar,
  Swipe,
  SwipeItem,
  Toast,
  Cell,
  CellGroup,
  Button,
  Dialog,
  ActionSheet,
} from "vant";
import { queryOrders, orderCancel, b2bPreorder } from "./service";
import qs from "qs";
import {
  VENDOR,
  ORDER_TYPE,
  PAYMENTITEM,
  SUCCESS,
  SHOPIFY_TAG,
} from "@/utils/constants";
import Check from "@/views/components/Check.vue";
import { formatThValue, formatSkuValue } from "@/utils/utils";
import { openWebPageLocale } from "@/utils/pages";

export default {
  props: ["orderType"],
  components: {
    [VanImage.name]: VanImage,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet,
    [Dialog.Component.name]: Dialog.Component,
    Check,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageSize: 10,
      orders: [],
      pageInfo: null,
      allChecked: false,
      ordersNo: 0,
      total: 0,
      cancelShow: false,
      cOrder: null,
      transportationShow: false,
      actions: [
        { name: this.$t("陆运"), code: 1 },
        { name: this.$t("海运"), code: 2 },
      ],
      expressWay: null,
      totalWeight: 0,
      totalVolume: 0,
      preorderFrieght: null,
      errorMessage: "",
      allAvailable: 0,
    };
  },
  methods: {
    toShippingPolicy() {
      openWebPageLocale("helpcenter", "shipping-policy", 1);
    },
    displayImage(node) {
      const { variant, attribute } = node;
      let url = variant && variant.image && variant.image.url;
      if (!url) url = attribute.image;
      return url;
    },
    async b2bPreorder() {
      const orderIds = [];

      const checkedOrders = this.orders.filter((order) => {
        return order.node.checked;
      });
      checkedOrders.forEach((order) => {
        const { id } = order.node;
        orderIds.push(id);
      });

      const payload = {
        orderIds: orderIds.join(","),
        expressWay: this.expressWay ? this.expressWay.code : 0,
        couponId: 0,
      };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await b2bPreorder(payload);

      Toast.clear();
      const { success, data, message } = res;
      if (success) {
        this.preorderFrieght = data;
      } else {
        this.errorMessage = message;
        Toast.fail(message);
      }
    },
    showTransportationType() {
      this.transportationShow = true;
    },
    onSelect(item) {
      this.expressWay = item;
      this.b2bPreorder();
    },
    toOrderDetail(id) {
      this.$router.push({
        name: "orderDetail",
        query: { id, status: ORDER_TYPE.toShip },
      });
    },
    isReadyToTransit(order) {
      const { ready_to_transit_time } = order.node;
      return ready_to_transit_time && ready_to_transit_time.value;
    },
    isOrderPacked(order) {
      if (!order) return;
      const { packed_time } = order.node;
      return packed_time && packed_time.value;
    },
    cancelOrder(order) {
      this.cOrder = order;
      this.cancelShow = true;
    },
    async onCancelConfirm() {
      if (!this.cOrder) return;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { order } = await orderCancel({
        orderId: this.cOrder.node.id,
      });
      Toast.clear();
      if (order) {
        const index = this.orders.findIndex((order) => {
          return order.node.id == this.cOrder.node.id;
        });
        this.orders.splice(index, 1);
        this.isAllOrderChecked();

        const { fulfillments } = this.cOrder.node;
        this.$router.push({
          name: "success",
          query: {
            successType:
              fulfillments && fulfillments.length > 0
                ? SUCCESS.CANCEL_PAID_SHIP
                : SUCCESS.CANCEL_PAID_UNSHIP,
          },
        });
      }
    },
    checkOrder(order) {
      if (!this.isAli1688Vendor(order) || !this.isReadyToTransit(order)) return;
      order.node.checked = !order.node.checked;
      this.isAllOrderChecked();
    },
    checkAllOrders() {
      this.allChecked = !this.allChecked;
      this.orders.forEach((order) => {
        if (!this.isAli1688Vendor(order) || !this.isReadyToTransit(order))
          return true;
        order.node.checked = this.allChecked;
      });
      this.calculateCheckedOrders();
    },
    isAllOrderChecked() {
      const allChecked = this.orders.every((order) => {
        if (!this.isAli1688Vendor(order) || !this.isReadyToTransit(order))
          return true;
        return order.node.checked;
      });

      this.allChecked = allChecked;
      this.calculateCheckedOrders();
    },
    calculateCheckedOrders() {
      let no = 0;
      let total = 0;
      let totalWeight = 0;
      let totalVolume = 0;
      let allAvailable = 0;
      this.orders.forEach((order) => {
        const { checked, totalPrice, actual_weight, actual_volume } =
          order.node;
        if (this.isReadyToTransit(order)) {
          allAvailable++;
        }
        if (checked) {
          no++;
          total = this.NumberAdd(total, totalPrice);

          if (actual_weight) {
            totalWeight = this.NumberAdd(totalWeight, actual_weight.value);
          }

          if (actual_weight) {
            totalVolume = this.NumberAdd(totalVolume, actual_volume.value);
          }
        }
      });
      this.ordersNo = no;
      this.total = total;
      this.totalWeight = totalWeight;
      this.totalVolume = totalVolume;
      this.allAvailable = allAvailable;

      if (this.ordersNo && this.expressWay) {
        this.b2bPreorder();
      }
    },
    toPay() {
      if (!this.expressWay) {
        Toast.fail(this.$t("Please select transportation type"));
        return;
      }

      if (!this.preorderFrieght) {
        Toast.fail(this.errorMessage);
        return;
      }

      const checkedOrders = this.orders.filter((order) => {
        return order.node.checked;
      });

      if (!checkedOrders && checkedOrders.length === 0) {
        return;
      }

      const orderIds = [];
      const orderNames = [];

      checkedOrders.forEach((order) => {
        const { id, name } = order.node;
        orderIds.push(id);
        orderNames.push(name);
      });

      const payload = {
        orderIds: orderIds.join(","),
        orderNames: orderNames.join(","),
        total: this.preorderFrieght.totalFee,
        paymentItem: PAYMENTITEM.b2b_freight,
        expressWay: this.expressWay.code,
        couponId: 0,
      };
      this.$router.push({
        name: "pay",
        query: { ...payload },
      });
    },
    onRefresh() {
      this.pageInfo = null;
      this.refreshing = true;
      this.getData();
    },
    onLoad() {
      this.loading = true;
      this.getData();
    },
    getSelectOptions(node) {
      const { attribute, variant } = node;
      const { id, selectedOptions } = variant;

      let sku;
      if (id) {
        const options = selectedOptions.map((opt) => {
          const { name, value } = opt;
          return `${this.formatThValue(name)}:${this.formatThValue(value)}`;
        });

        sku = options.join(" ");
      } else {
        sku = formatSkuValue(attribute["sku"]);
      }
      return sku;
    },
    formatThValue(value) {
      return formatThValue(value);
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getOrderNo({ node }) {
      let isOverSea = this.isAli1688Vendor({ node });
      return node.name + (isOverSea ? `(${this.$t("overseas")})` : "");
    },
    isAli1688Vendor({ node }) {
      return node.tags && node.tags.includes(SHOPIFY_TAG.cross_border_to_ship);
    },
    async queryOrders() {
      const query = `status:open ((financial_status:paid tag:cross_border_to_ship) OR (financial_status:paid  fulfillment_status:unshipped tag:local) OR (financial_status:partially_refunded tag:cross_border_to_ship))`;

      const after =
        this.pageInfo && this.pageInfo.endCursor ? this.pageInfo.endCursor : "";
      const payload = {
        first: this.pageSize,
        reverse: true,
        sortKey: "UPDATED_AT",
        query,
        returnsQlStr: `{
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      endCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        name
                        tags
                        totalPrice
                        totalShippingPrice
                        subtotalPrice
                        fulfillments {
                          trackingInfo {
                            number
                          }
                        }    
                        lineItems(first:10) {
                          edges {
                            node {
                              id
                              quantity
                              title
                              product {
                                id
                                vendor
                                title
                                local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                                  value
                                }
                              }
                              discountedUnitPrice
                              customAttributes {
                                key
                                value
                              }
                              variant {
                                id
                                title
                                price
                                image {
                                  url
                                }
                                selectedOptions {
                                  name
                                  value
                                }
                              }
                            }
                          }
                        }
                        actual_weight:metafield(namespace:\\"custom\\",key:\\"actual_weight\\"){
                          value
                        }
                        actual_volume:metafield(namespace:\\"custom\\",key:\\"actual_volume\\"){
                          value
                        }
                        ready_to_transit_time:metafield(namespace:\\"custom\\",key:\\"ready_to_transit_time\\"){
                          value
                        }
                        packed_time:metafield(namespace:\\"custom\\",key:\\"packed_time\\"){
                          value
                        }
                      }
                    }
                }`,
      };

      if (after) payload.after = after;

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await queryOrders(qs.stringify(payload));
      this.refreshing = false;
      this.loading = false;
      Toast.clear();
      const { orders } = data;
      if (orders) {
        const { edges, pageInfo } = orders;

        edges.forEach((order) => {
          const { lineItems } = order.node;
          lineItems.edges.forEach((item) => {
            const { customAttributes, variant, discountedUnitPrice, title } =
              item.node;

            let attribute = {};
            customAttributes.forEach((attr) => {
              const { key, value } = attr;
              attribute[key] = value;
            });

            item.node.attribute = attribute;

            if (!variant) {
              item.node.product = {
                id: attribute.productId,
                title,
                vendor: attribute.vendor,
              };
              item.node.variant = {
                image: { url: attribute.image },
                price: discountedUnitPrice,
              };
            } else variant.price = discountedUnitPrice;
          });
        });

        if (!pageInfo.hasPreviousPage) this.orders = [];
        this.orders = this.orders.concat(edges);
        this.pageInfo = pageInfo;
        this.finished = !pageInfo.hasNextPage;

        this.checkedAllOrders();
      }
    },
    checkedAllOrders() {
      this.orders.forEach((order) => {
        const { node } = order;
        if (!this.isAli1688Vendor(order) || !this.isReadyToTransit(order))
          return true;
        node.checked = true;
      });
      this.allChecked = true;
      this.calculateCheckedOrders();
    },
    getData() {
      this.queryOrders();
    },
  },
  mounted() {
    this.pageInfo = null;
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.cart-item {
  display: flex;
  padding: 15px;
  background-color: white;
}

.cart-item-content {
  flex: 1;
  padding-left: 10px;
}

.cart-item-price {
  display: inline-block;
  padding: 0px 5px;
  font-size: 1.5rem;
}

.cart-item-bottom {
  display: flex;
  align-items: baseline;
}

.sku {
  color: #999;
  font-size: 0.8rem;
  padding: 5px 0px;
}

.product-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.quantity {
  flex: 1;
  text-align: end;
  color: #333;
  font-size: large;
}

.order-item {
  font-size: 0.9rem;
  color: #555;

  .order-item-title {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: white;
  }

  .order-item-cancel {
    color: @primaryColor;
    padding-left: 15px;
  }
}

.notice-to-ship {
  padding: 0px 15px;
  color: #555;
  font-size: 0.8rem;
}

.cross-border-freight {
  background-color: white;
  padding: 10px 15px;
  margin: 10px 0px;
}

.orders-content {
  background-color: white;
  margin-top: 10px;
}

.orders-pay-all {
  padding: 10px 15px;
}

.to-pay-bottom {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: white;
}

.ship-total {
  display: flex;
  height: 45px;
  align-items: center;
}

.button-pay-now {
  margin: 0px 10px;
}

.to-pay-left {
  flex: 1;
  padding: 0px 15px;
  font-size: 0.9rem;
}

.freight-item {
  display: flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
  padding: 0px 15px;
  font-size: 0.9rem;
}

.freight-item-title {
  flex: 1;
}

.freight-item-amount {
  display: flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
  padding: 0px 15px 0px 30px;
  color: #999;
  font-size: 0.9rem;
}

:deep(.van-cell__title) {
  -webkit-flex: none;
  -webkit-box-flex: none;
  flex: none;
}

.subtotal-value {
  color: #555;
}

.copy {
  color: #3399ff;
  display: inline-block;
  padding-left: 5px;
}

.bottom-space {
  margin-bottom: 300px;
}

:deep(.van-overlay) {
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
}

:deep(.van-popup) {
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>