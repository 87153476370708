<template>
  <div>
    <van-tabs
      v-model="active"
      swipe-threshold="4"
      color="#EE502F"
      title-active-color="#EE502F"
      title-inactive-color="#333"
      sticky
      style="max-width: 500px; margin: 0 auto"
      swipeable
    >
      <van-tab :title="$t('All')" v-if="false">
        <div class="index-content">
          <Order :orderType="0" />
        </div>
      </van-tab>
      <van-tab :title="$t('To be confirmed')">
        <div class="index-content">
          <ToBeConfirmed />
        </div>
      </van-tab>
      <van-tab :title="$t('To Pay')">
        <div class="index-content">
          <ToPay @change="onValueChange" />
        </div>
      </van-tab>
      <van-tab :title="$t('To Ship')">
        <div class="index-content">
          <ToShip />
        </div>
      </van-tab>
      <van-tab :title="$t('To Receive')">
        <div class="index-content">
          <ToReceive />
        </div>
      </van-tab>
      <van-tab :title="$t('Cancelled')">
        <div class="index-content">
          <Cancelled />
        </div>
      </van-tab>
      <van-tab :title="$t('Completed')">
        <div class="index-content">
          <Completed />
        </div>
      </van-tab>
    </van-tabs>

    <div class="navi-home-parent mobile-fixed-theme">
      <div class="navi-home" @click="toPageRoute('home')" v-if="isRedirect">
        <van-icon name="wap-home-o" size="30" style="line-height: 50px" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  List,
  PullRefresh,
  Image as VanImage,
  Icon,
  Empty,
  NoticeBar,
  Swipe,
  SwipeItem,
  Toast,
} from "vant";
import Cancelled from "./Cancelled.vue";
import ToBeConfirmed from "./ToBeConfirmed.vue";
import ToPay from "./ToPay.vue";
import ToShip from "./ToShip.vue";
import ToReceive from "./ToReceive.vue";
import Completed from "./Completed.vue";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Cancelled,
    ToPay,
    ToBeConfirmed,
    ToShip,
    ToReceive,
    Completed,
  },
  data() {
    return {
      active: 0,
      isRedirect: this.$route.query.isRedirect,
    };
  },
  methods: {
    onValueChange(orderType, param) {},
    toPageRoute(name) {
      this.$store.commit("setHomeIndex", "home" == name ? 0 : 1);
      this.$router.push({ name: "Home" });
    },
  },
  mounted() {
    const { orderIndex } = this.$store.state;
    if (orderIndex >= 0) this.active = orderIndex;
    else {
      this.active = this.$route.query.orderType
        ? this.$route.query.orderType - 1
        : 0;
    }
  },
  destroyed() {
    this.$store.commit("setOrderIndex", this.active);
  },
};
</script>
<style lang="less" scoped>
.navi-home-parent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 55px;
  z-index: 20;
  pointer-events: none;
}
.navi-home {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #ffffff66;
  border-radius: 25px;
  border: solid 1px #555;
  margin-left: 15px;
  text-align: center;
  pointer-events: auto;
}

:deep(.van-sticky--fixed) {
  max-width: 500px;
  margin: 0 auto;
}
</style>