<template>
  <div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        style="
                margin-bottom: 50px;height:100%'
            "
        :finished-text="orders.length ? $t('没有更多了') : ''"
        @load="onLoad"
      >
        <div class="notice-to-pay" v-if="orders && orders.length !== 0">
          {{ $t("notice to pay") }}
        </div>

        <div class="orders-content" v-if="orders && orders.length !== 0">
          <div
            class="orders-pay-all van-hairline--bottom"
            @click="checkAllOrders"
          >
            <Check
              :check="allChecked"
              :disable="!totalCanBeChecked"
              style="display: inline-block"
            />
            <span style="display: inline-block; padding-left: 5px">{{
              $t("Pay for all orders")
            }}</span>
          </div>
          <div style="height: 10px; background-color: #f2f2f2"></div>
          <div
            v-for="order in orders"
            :key="order.node.id"
            class="order-item"
            @click="toOrderDetail(order.node.id)"
          >
            <div class="order-item-title">
              <div style="flex: 1" @click.stop="checkOrder(order)">
                <Check
                  :check="order.node.checked"
                  :disable="!order.node.canBeSelected"
                  style="display: inline-block"
                />
                <span style="padding-left: 5px">{{ getOrderNo(order) }}</span>
              </div>
              <span
                class="order-item-cancel"
                @click.stop="cancelOrder(order)"
                >{{ $t("Cancel Order") }}</span
              >
            </div>
            <div v-for="line in order.node.lineItems.edges" :key="line.node.id">
              <div
                class="cart-item van-hairline--top-bottom"
                v-if="line.node.product"
              >
                <van-image
                  width="80"
                  height="80"
                  @click.stop="toProduct(line.node.product.id)"
                  :src="displayImage(line.node)"
                />
                <div class="cart-item-content">
                  <div class="cart-item-bottom">
                    <div class="product-title">
                      {{
                        line.node.product.local_title
                          ? line.node.product.local_title.value
                          : line.node.product.title
                      }}
                    </div>
                    <span>฿{{ line.node.variant.price }}</span>
                  </div>
                  <div
                    class="cart-item-bottom"
                    style="color: #999; padding: 5px 0px"
                  >
                    <span style="flex: 1">
                      {{ getSelectOptions(line.node) }}
                    </span>
                    <span> x{{ line.node.quantity }} </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="van-hairline--top van-hairline--bottom"
              style="padding: 10px 0px"
            >
              <div
                class="freight-item"
                style="padding-bottom: 5px"
                v-if="order.node.totalDiscounts"
              >
                <span class="freight-item-title">{{ $t("优惠") }}</span>
                <span>{{ order.node.totalDiscounts }}</span>
              </div>
              <div class="freight-item" style="padding-bottom: 5px">
                <span class="freight-item-title">{{ $t("Freight") }}</span>
                <span>{{
                  calculateingPrice(order.node.totalShippingPrice)
                }}</span>
              </div>
              <div class="freight-item">
                <span class="freight-item-title">{{ $t("Subtotal") }}</span>
                <span>{{ calculateingPrice(order.node.subtotalPrice) }}</span>
              </div>
            </div>

            <div class="estimate-freight">
              <div>{{ $t("Additional Cross-border Freight") }}</div>
              <div class="freight-item">
                <span class="freight-item-title">{{
                  $t("by land transpotation")
                }}</span>
                <span>{{
                  calculateingPrice(order.node.landFreightTotal)
                }}</span>
              </div>
              <div class="freight-item">
                <span class="freight-item-title">{{
                  $t("by sea transpotation")
                }}</span>
                <span>{{ calculateingPrice(order.node.seaFreightTotal) }}</span>
              </div>

              <div style="color: red; padding-top: 10px">
                {{ $t("This is an estimate and actual amounts may vary.") }}
              </div>
            </div>
            <!-- <van-cell title="Total" :value="'฿' + order.node.totalPrice" /> -->
            <div style="height: 10px; background-color: #f2f2f2"></div>
          </div>
        </div>
      </van-list>

      <van-empty
        v-if="!orders.length"
        image="search"
        :description="$t('暂无数据')"
      />
    </van-pull-refresh>

    <div class="to-pay-bottom" v-if="ordersNo">
      <div class="to-pay-left">
        <span>{{ $t("orders amount", { amount: ordersNo }) }}</span>
        <br />
        <span>{{ $t("Total") }}: ฿{{ total }}</span>
      </div>

      <van-button
        class="button-pay-now"
        @click="toPay"
        color="#EE502F"
        size="small"
        style="font-size: 0.9rem; width: 100px"
        round
      >
        {{ $t("Pay") }}
      </van-button>
    </div>

    <van-dialog
      v-model="cancelShow"
      title=""
      show-cancel-button
      :cancelButtonText="$t('取消')"
      :confirmButtonText="$t('确定')"
      @confirm="onCancelConfirm"
    >
      <div style="padding: 20px 15px">
        <div style="text-align: center">
          {{
            $t("Are you sure you want to cancel order", {
              orderName: cOrder && cOrder.node.name,
            })
          }}
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  List,
  PullRefresh,
  Image as VanImage,
  Icon,
  Empty,
  NoticeBar,
  Swipe,
  SwipeItem,
  Toast,
  Cell,
  CellGroup,
  Button,
  Dialog,
} from "vant";
import { queryOrders, orderCancel } from "./service";
import qs from "qs";
import {
  VENDOR,
  ORDER_TYPE,
  PAYMENTITEM,
  SUCCESS,
  SHOPIFY_TAG,
} from "@/utils/constants";
import Check from "@/views/components/Check.vue";
import { formatThValue, formatSkuValue } from "@/utils/utils";

export default {
  props: ["orderType"],
  components: {
    [VanImage.name]: VanImage,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    Check,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageSize: 10,
      orders: [],
      pageInfo: null,
      allChecked: false,
      ordersNo: 0,
      total: 0,
      cancelShow: false,
      cOrder: null,
      totalCanBeChecked: 0,
    };
  },
  methods: {
    displayImage(node) {
      const { variant, attribute } = node;
      let url = variant && variant.image && variant.image.url;
      if (!url) url = attribute.image;
      return url;
    },
    calculateingPrice(price) {
      return price ? `฿${price}` : this.$t("Calculating");
    },
    isOrderCheckedable(order) {
      const { landFreightTotal, seaFreightTotal } =
        this.calculateEstimateFreight(order);
      return landFreightTotal || seaFreightTotal;
    },
    toOrderDetail(id) {
      this.$router.push({
        name: "orderDetail",
        query: { id, status: ORDER_TYPE.toPay },
      });
    },
    isAli1688Vendor({ node }) {
      // const { lineItems } = order.node;
      // if (lineItems && lineItems.edges && lineItems.edges.length !== 0) {
      //   const line = lineItems.edges[0];
      //   const { product } = line.node;
      //   return product && product.vendor == VENDOR.ali1688;
      // }
      // return false;
      return node.tags && node.tags.includes(SHOPIFY_TAG.cross_border);
    },
    cancelOrder(order) {
      this.cOrder = order;
      this.cancelShow = true;
    },
    async onCancelConfirm() {
      if (!this.cOrder) return;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { order } = await orderCancel({
        orderId: this.cOrder.node.id,
      });
      Toast.clear();
      if (order) {
        const index = this.orders.findIndex((order) => {
          return order.node.id == this.cOrder.node.id;
        });
        this.orders.splice(index, 1);
        this.isAllOrderChecked();

        this.$router.push({
          name: "success",
          query: {
            successType: SUCCESS.CANCEL_UNPAY,
          },
        });
      }
    },
    checkOrder(order) {
      if (!order.node.canBeSelected) return;
      order.node.checked = !order.node.checked;
      this.isAllOrderChecked();
    },
    checkAllOrders() {
      if (!this.totalCanBeChecked) return;

      this.allChecked = !this.allChecked;
      this.orders.forEach((order) => {
        if (order.node.canBeSelected) order.node.checked = this.allChecked;
      });
      this.calculateCheckedOrders();
    },
    isAllOrderChecked() {
      const allChecked = this.orders.every((order) => {
        return order.node.canBeSelected ? order.node.checked : true;
      });

      this.allChecked = allChecked;
      this.calculateCheckedOrders();
    },
    calculateEstimateFreight(order) {
      let landFreightTotal = 0;
      let seaFreightTotal = 0;

      const { estimation_first_leg_freight, estimation_second_leg_freight } =
        order.node;

      if (estimation_first_leg_freight) {
        const firstLegFreight = JSON.parse(estimation_first_leg_freight.value);

        if (firstLegFreight && firstLegFreight.length === 2) {
          const landFreight = firstLegFreight[0];
          const seadFreight = firstLegFreight[1];

          landFreightTotal = this.NumberAdd(landFreightTotal, landFreight);
          seaFreightTotal = this.NumberAdd(seaFreightTotal, seadFreight);
        }
      }
      if (estimation_second_leg_freight) {
        landFreightTotal = this.NumberAdd(
          landFreightTotal,
          estimation_second_leg_freight.value
        );
        seaFreightTotal = this.NumberAdd(
          seaFreightTotal,
          estimation_second_leg_freight.value
        );
      }
      return { landFreightTotal, seaFreightTotal };
    },
    calculateCheckedOrders() {
      let no = 0;
      let total = 0;
      this.orders.forEach((order) => {
        const { checked, totalPrice } = order.node;
        if (checked) {
          no++;
          total = this.NumberAdd(total, totalPrice);
        }
      });
      this.ordersNo = no;
      this.total = total;
    },
    toPay() {
      const checkedOrders = this.orders.filter((order) => {
        return order.node.checked;
      });

      if (!checkedOrders && checkedOrders.length === 0) {
        return;
      }

      const orderIds = [];
      const orderNames = [];

      checkedOrders.forEach((order) => {
        const { id, name } = order.node;
        orderIds.push(id);
        orderNames.push(name);
      });

      const payload = {
        orderIds: orderIds.join(","),
        orderNames: orderNames.join(","),
        total: this.total,
        paymentItem: PAYMENTITEM.b2b_order,
      };
      this.$router.push({
        name: "pay",
        query: { ...payload },
      });
    },
    onRefresh() {
      this.pageInfo = null;
      this.refreshing = true;
      this.getData();
    },
    onLoad() {
      this.loading = true;
      this.getData();
    },
    getSelectOptions(node) {
      const { attribute, variant } = node;
      const { id, selectedOptions } = variant;

      let sku;
      if (id) {
        const options = selectedOptions.map((opt) => {
          const { name, value } = opt;
          return `${this.formatThValue(name)}:${this.formatThValue(value)}`;
        });

        sku = options.join(" ");
      } else {
        sku = formatSkuValue(attribute["sku"]);
      }
      return sku;
    },
    formatThValue(value) {
      return formatThValue(value);
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getOrderNo({ node }) {
      let isOverSea = this.isAli1688Vendor({ node });
      return node.name + (isOverSea ? `(${this.$t("overseas")})` : "");
    },
    async queryOrders() {
      let query = `status:open financial_status:pending`;

      const after =
        this.pageInfo && this.pageInfo.endCursor ? this.pageInfo.endCursor : "";
      const payload = {
        first: this.pageSize,
        reverse: true,
        sortKey: "UPDATED_AT",
        query,
        returnsQlStr: `{
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      endCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        name
                        tags
                        totalPrice
                        totalShippingPrice
                        subtotalPrice
                        totalDiscounts
                        fulfillments {
                          trackingInfo {
                            number
                          }
                        }    
                        lineItems(first:10) {
                          edges {
                            node {
                              id
                              quantity
                              title
                              product {
                                id
                                vendor
                                title
                                local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                                  value
                                }
                              }
                              discountedUnitPrice
                              customAttributes {
                                key
                                value
                              }
                              variant {
                                id
                                title
                                price
                                image {
                                  url
                                }
                                selectedOptions {
                                  name
                                  value
                                }
                              }
                            }
                          }
                        }
                        estimation_first_leg_freight:metafield(namespace:\\"custom\\",key:\\"estimation_first_leg_freight\\"){
                          value
                        }
                        estimation_second_leg_freight:metafield(namespace:\\"custom\\",key:\\"estimation_second_leg_freight\\"){
                          value
                        }
                      }
                    }
                }`,
      };

      if (after) payload.after = after;

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data, message } = await queryOrders(qs.stringify(payload));
      this.refreshing = false;
      this.loading = false;
      Toast.clear();

      if (message) {
        Toast.fail(message);
        return;
      }

      const { orders } = data;
      if (orders) {
        const { edges, pageInfo } = orders;

        edges.forEach((order) => {
          const { lineItems } = order.node;
          lineItems.edges.forEach((item) => {
            const { customAttributes, variant, discountedUnitPrice, title } =
              item.node;

            let attribute = {};
            customAttributes.forEach((attr) => {
              const { key, value } = attr;
              attribute[key] = value;
            });

            item.node.attribute = attribute;

            if (!variant) {
              item.node.product = {
                id: attribute.productId,
                title,
                vendor: attribute.vendor,
              };
              item.node.variant = {
                image: { url: attribute.image },
                price: discountedUnitPrice,
              };
            } else {
              variant.price = discountedUnitPrice;
            }
          });

          const { landFreightTotal, seaFreightTotal } =
            this.calculateEstimateFreight(order);
          order.node.landFreightTotal = landFreightTotal;
          order.node.seaFreightTotal = seaFreightTotal;
          order.node.canBeSelected = landFreightTotal || seaFreightTotal;
        });

        if (!pageInfo.hasPreviousPage) this.orders = [];
        this.orders = this.orders.concat(edges);
        this.finished = !pageInfo.hasNextPage;
        this.pageInfo = pageInfo;
        this.checkedAllOrders();
      }
    },
    checkedAllOrders() {
      this.orders.forEach((order) => {
        const { node } = order;
        if (node.canBeSelected) node.checked = true;
      });

      const canBeSelectedOrders = this.orders.filter((order) => {
        const { node } = order;
        return node.canBeSelected;
      });

      this.totalCanBeChecked = canBeSelectedOrders.length;
      this.allChecked = this.totalCanBeChecked > 0;
      this.calculateCheckedOrders();
    },
    getData() {
      this.queryOrders();
    },
  },
  mounted() {
    this.pageInfo = null;
    this.refreshing = true;
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.cart-item {
  display: flex;
  padding: 15px;
  background-color: white;
}

.cart-item-content {
  flex: 1;
  padding-left: 10px;
}

.cart-item-price {
  display: inline-block;
  padding: 0px 5px;
  font-size: 1.5rem;
}

.cart-item-bottom {
  display: flex;
  align-items: baseline;
}

.sku {
  color: #999;
  font-size: 0.8rem;
  padding: 5px 0px;
}

.product-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.quantity {
  flex: 1;
  text-align: end;
  color: #333;
  font-size: large;
}

.order-item {
  font-size: 0.9rem;
  color: #555;

  .order-item-title {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: white;
  }

  .order-item-cancel {
    color: @primaryColor;
    padding-left: 15px;
  }
}

.notice-to-pay {
  padding: 10px 15px;
  color: white;
  background-color: #ee502f;
}

.orders-content {
  background-color: white;
  margin-top: 10px;
}

.orders-pay-all {
  padding: 10px 15px;
}

.to-pay-bottom {
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0;
  right: 0;
  align-items: center;
  background-color: white;
  height: 45px;
}

.button-pay-now {
  margin: 0px 10px;
}

.to-pay-left {
  flex: 1;
  padding: 0px 15px;
  font-size: 0.9rem;
}

.estimate-freight {
  padding: 10px 15px;
}

.freight-item {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  font-size: 0.9rem;
}

.freight-item-title {
  flex: 1;
}
</style>