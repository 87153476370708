<template>
  <div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        style="
                margin-bottom: 50px;height:100%'
            "
        :finished-text="orders.length ? $t('没有更多了') : ''"
        @load="onLoad"
      >
        <div
          v-for="order in orders"
          :key="order.node.id"
          class="order-item"
          @click="toOrderDetail(order.node.id)"
        >
          <div class="order-item-title">
            <span style="flex: 1">{{ getOrderNo(order) }}</span>
          </div>
          <div v-for="line in order.node.lineItems.edges" :key="line.node.id">
            <div
              class="cart-item van-hairline--top-bottom"
              v-if="line.node.product"
            >
              <van-image
                width="80"
                height="80"
                @click.stop="toProduct(line.node.product.id)"
                :src="displayImage(line.node)"
              />
              <div class="cart-item-content">
                <div class="cart-item-bottom">
                  <div class="product-title">
                    {{
                      line.node.product.local_title
                        ? line.node.product.local_title.value
                        : line.node.product.title
                    }}
                  </div>
                  <span>฿{{ line.node.variant.price }}</span>
                </div>
                <div
                  class="cart-item-bottom"
                  style="color: #999; padding: 5px 0px"
                >
                  <span style="flex: 1">
                    {{ getSelectOptions(line.node) }}
                  </span>
                  <span> x{{ line.node.quantity }} </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="van-hairline--top van-hairline--bottom"
            style="background-color: white; padding: 5px 0px"
          >
            <div class="freight-item">
              <span class="freight-item-title">{{ $t("Tracking") }}</span>
              <span>{{ getTracking(order) }}</span>
              <van-icon name="arrow" />
            </div>
          </div>

          <div style="background-color: white">
            <div class="freight-item">
              <span class="freight-item-title">{{ $t("Subtotal") }}</span>
              <span>฿{{ order.node.subtotalPrice }}</span>
            </div>
            <div class="freight-item">
              <span class="freight-item-title">{{ $t("Freight") }}</span>
              <span>฿{{ order.node.totalShippingPrice }}</span>
            </div>
            <div class="freight-item">
              <span class="freight-item-title" style="font-weight: bold"
                >Total</span
              >
              <span>฿{{ order.node.totalPrice }}</span>
            </div>
          </div>
        </div>
      </van-list>

      <van-empty
        v-if="!orders.length"
        image="search"
        :description="$t('暂无数据')"
      />
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  List,
  PullRefresh,
  Image as VanImage,
  Icon,
  Empty,
  NoticeBar,
  Swipe,
  SwipeItem,
  Toast,
  Cell,
  CellGroup,
} from "vant";
import { queryOrders } from "./service";
import qs from "qs";
import { VENDOR, ORDER_TYPE } from "@/utils/constants";
import { formatThValue, formatSkuValue } from "@/utils/utils";

export default {
  props: ["orderType"],
  components: {
    [VanImage.name]: VanImage,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageSize: 10,
      orders: [],
      pageInfo: null,
    };
  },
  methods: {
    displayImage(node) {
      const { variant, attribute } = node;
      let url = variant && variant.image && variant.image.url;
      if (!url) url = attribute.image;
      return url;
    },
    onRefresh() {
      this.pageInfo = null;
      this.refreshing = true;
      this.getData();
    },
    onLoad() {
      this.loading = true;
      this.getData();
    },
    getTracking(order) {
      const { tracking } = order.node;
      if (tracking) {
        const route = JSON.parse(tracking.value);
        return route.tracking;
      }
      return;
    },
    toOrderDetail(id) {
      let status = ORDER_TYPE.toReceive;
      this.$router.push({
        name: "orderDetail",
        query: { id, status },
      });
    },
    getSelectOptions(node) {
      const { attribute, variant } = node;
      const { id, selectedOptions } = variant;

      let sku;
      if (id) {
        const options = selectedOptions.map((opt) => {
          const { name, value } = opt;
          return `${this.formatThValue(name)}:${this.formatThValue(value)}`;
        });

        sku = options.join(" ");
      } else {
        sku = formatSkuValue(attribute["sku"]);
      }
      return sku;
    },
    formatThValue(value) {
      return formatThValue(value);
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getOrderNo({ node }) {
      let isOverSea = false;
      const { edges } = node.lineItems;
      if (edges && edges.length !== 0) {
        const edge = edges[0];
        const { product } = edge.node;
        if (!product) {
          return node.name;
        }
        const { vendor } = product;
        isOverSea = VENDOR.ali1688 == vendor;
      }

      return node.name + (isOverSea ? `(${this.$t("overseas")})` : "");
    },
    async queryOrders() {
      let query = `status:closed`;

      const after =
        this.pageInfo && this.pageInfo.endCursor ? this.pageInfo.endCursor : "";
      const payload = {
        first: this.pageSize,
        reverse: true,
        sortKey: "UPDATED_AT",
        query,
        returnsQlStr: `{
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      endCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        name
                        tags
                        totalPrice
                        subtotalPrice
                        totalShippingPrice
                        fulfillments {
                          trackingInfo {
                            number
                          }
                        }    
                        lineItems(first:10) {
                          edges {
                            node {
                              id
                              quantity
                              title
                              product {
                                id
                                vendor
                                title
                                local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                                  value
                                }
                              }
                              discountedUnitPrice
                              customAttributes {
                                key
                                value
                              }
                              variant {
                                id
                                title
                                price
                                image {
                                  url
                                }
                                selectedOptions {
                                  name
                                  value
                                }
                              }
                            }
                          }
                        }
                        tracking:metafield(namespace:\\"custom\\",key:\\"tracking\\"){
                          value
                        }
                      }
                    }
                }`,
      };

      if (after) payload.after = after;

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await queryOrders(qs.stringify(payload));
      this.refreshing = false;
      this.loading = false;
      Toast.clear();
      const { orders } = data;
      if (orders) {
        const { edges, pageInfo } = orders;

        edges.forEach((order) => {
          const { lineItems } = order.node;
          lineItems.edges.forEach((item) => {
            const { customAttributes, variant, discountedUnitPrice, title } =
              item.node;
            let attribute = {};
            customAttributes.forEach((attr) => {
              const { key, value } = attr;
              attribute[key] = value;
            });
            item.node.attribute = attribute;

            if (!variant) {
              item.node.product = {
                id: attribute.productId,
                title,
                vendor: attribute.vendor,
              };
              item.node.variant = {
                image: { url: attribute.image },
                price: discountedUnitPrice,
              };
            } else variant.price = discountedUnitPrice;
          });
        });

        if (!pageInfo.hasPreviousPage) this.orders = [];
        this.orders = this.orders.concat(edges);
        this.pageInfo = pageInfo;
        this.finished = !pageInfo.hasNextPage;
      }
    },
    getData() {
      this.queryOrders();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.cart-item {
  display: flex;
  padding: 15px;
  background-color: white;
}

.cart-item-content {
  flex: 1;
  padding-left: 10px;
}

.cart-item-price {
  display: inline-block;
  padding: 0px 5px;
  font-size: 1.5rem;
}

.cart-item-bottom {
  display: flex;
  align-items: baseline;
}

.sku {
  color: #999;
  font-size: 0.8rem;
  padding: 5px 0px;
}

.product-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.quantity {
  flex: 1;
  text-align: end;
  color: #333;
  font-size: large;
}

.order-item {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #555;

  .order-item-title {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: white;
  }

  .order-item-cancel {
    color: @primaryColor;
    padding-left: 15px;
  }
}

.freight-item {
  display: flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
  padding: 0px 15px;
  font-size: 0.9rem;
}

.freight-item-title {
  flex: 1;
}
</style>