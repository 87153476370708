<template>
  <div>
    <van-icon
      v-if="!disable && !check"
      name="circle"
      size="16"
      color="#c8c9cc"
    />
    <van-icon
      v-if="!disable && check"
      name="checked"
      size="16"
      color="#EE502F"
    />
    <van-icon
      v-if="disable"
      name="circle"
      size="16"
      color="rgb(200, 201, 204)"
      style="background-color: #EBECF0; border-radius: 8px"
    />
  </div>
</template>

<script>
import { Icon } from "vant";
export default {
  props: ["check", "disable"],
  components: {
    [Icon.name]: Icon,
  },
};
</script>

<style lang="less" scoped>
.disable {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #f2f2f2;
  border: solid 1px rgb(200, 201, 204);
}
</style>